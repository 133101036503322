@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100vh;
	/*background: linear-gradient(90deg, "#454545" 1%, "#272727" 96%, "#454545" 100%);*/
	background: linear-gradient(90deg, #454545  2%, #272727 3%, #272727 97%, #454545 98%);



}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
	color: #fff !important
}

code {
	font-family: 'Montserrat', sans-serif;
}

